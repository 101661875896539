import React, { useEffect, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import OtpInput from "react-otp-input";
import { CurrencyComponent } from "../../../../core/helpers";
import moment from "moment";
import { authService } from "../../../../services/authService";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  createTransactionPin,
  getAllProducts,
  getTransactions,
  getWalletBalance,
  handleCreateProduct,
  handleUpdateProduct,
  todoListStatus,
} from "../../../../store/actions";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import ForgotTransactionPinPopup from "../forgot_transaction_pin/ForgotTransactionPinPopup";

const PaymentCheckoutPopup = ({
  fetchedCreateInfo,
  setOpenPaymentSummaryModal,
  setOpenPaymentSuccessfulModal,
  setOpenPurchaseSummaryModal,
  setIsNew,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [createPinOTP, setCreatePinOTP] = useState("");
  const [createPinOTP2, setCreatePinOTP2] = useState("");
  const [openCreateotp, setOpenCreateotp] = useState(false);
  const [openForgototp, setOpenForgototp] = useState(false);
  const [fetchedStatuses, setfetchedStatuses] = useState("");

  const { allProducts } = useSelector((state) => state.productShare);
  const { todoListStatusData } = useSelector((state) => state.masterData);

  const customerId = authService.getCurrentUserCustomerId();
  const userId = authService.getCurrentUserID();
  const verificationCode = authService.getCurrentUserVerificationCode();

  useEffect(() => {
    if (!todoListStatusData) {
      const data = {
        sql_ref_no: "20",
        user_id: userId?.toString(),
        verification_code: verificationCode?.toString(),
        option: "1",
      };

      dispatch(todoListStatus(data));
    }

    if (todoListStatusData) setfetchedStatuses(todoListStatusData);
  }, [dispatch, todoListStatusData, verificationCode, userId]);

  const handleSubmit = () => {
    try {
      const toastId = toast.loading("Creating Product");

      const data = {
        gropool_date: fetchedCreateInfo?.data?.gropool_date,
        user_id: fetchedCreateInfo?.data?.user_id,
        slot_number: fetchedCreateInfo?.data?.slot_number,
        item_id: fetchedCreateInfo?.data?.item_id,
        item_price: (
          +fetchedCreateInfo?.data?.item_price +
          allProducts?.gropool_delivery_charge[0]?.delivery_charge
        ).toString(),
        purchase_slot: fetchedCreateInfo?.data?.purchase_slot,
        sharing_name: fetchedCreateInfo?.data?.name,
        notification_status_id: fetchedCreateInfo?.data?.notification_status_id,
        recipient_ref_no: fetchedCreateInfo?.data?.recipient_ref_no
          ? fetchedCreateInfo?.data?.recipient_ref_no
          : "0",
        gift_status_id: fetchedCreateInfo?.data?.gift_status_id
          ? fetchedCreateInfo?.data?.gift_status_id
          : "2",
        delivery_charge: allProducts?.gropool_delivery_charge?.length
          ? allProducts?.gropool_delivery_charge[0]?.delivery_charge.toString()
          : "0",
        pin_number: otp,
      };

      const payload = {
        customerId,
        data,
      };

      dispatch(handleCreateProduct(payload))
        .unwrap()
        .then((res) => {
          if (res.status === 403) {
            return toast.error(res?.message, { id: toastId });
          }
          dispatch(getAllProducts());
          setOpenPaymentSummaryModal(false);
          setOpenPaymentSuccessfulModal(true);
          handleWalletUpdate();
          setIsNew(true);
          return toast.success(res.message_text, { id: toastId });
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleUpdateSubmit = () => {
    try {
      const toastId = toast.loading("Creating Product");

      const data = {
        gropool_date: fetchedCreateInfo?.data?.gropool_date,
        user_id: fetchedCreateInfo?.data?.user_id,
        slot_number: fetchedCreateInfo?.data?.slot_number,
        item_id: fetchedCreateInfo?.data?.item_id,
        item_price: (
          +fetchedCreateInfo?.data?.item_price +
          allProducts?.gropool_delivery_charge[0]?.delivery_charge
        ).toString(),
        purchase_slot: fetchedCreateInfo?.data?.purchase_slot,
        sharing_name: fetchedCreateInfo?.data?.name,
        notification_status_id: fetchedCreateInfo?.data?.notification_status_id,
        recipient_ref_no: fetchedCreateInfo?.data?.recipient_ref_no
          ? fetchedCreateInfo?.data?.recipient_ref_no
          : "0",
        gift_status_id: fetchedCreateInfo?.data?.gift_status_id
          ? fetchedCreateInfo?.data?.gift_status_id
          : "2",
        pin_number: otp,
        ref_no: fetchedCreateInfo?.data?.ref_no,
        save_recipient_status_id:
          fetchedCreateInfo?.data?.save_recipient_status_id,
        delivery_charge: allProducts?.gropool_delivery_charge?.length
          ? allProducts?.gropool_delivery_charge[0]?.delivery_charge.toString()
          : "0",
      };

      const payload = {
        customerId,
        data,
      };

      dispatch(handleUpdateProduct(payload))
        .unwrap()
        .then((res) => {
          if (res.status === 403) {
            return toast.error(res?.message, { id: toastId });
          }
          dispatch(getAllProducts());
          setOpenPaymentSummaryModal(false);
          setOpenPaymentSuccessfulModal(true);
          handleWalletUpdate();
          setIsNew(false);
          return toast.success(res.message_text, { id: toastId });
        })
        .catch((err) => err);
    } catch (err) {
      return err;
    }
  };

  const handleWalletUpdate = () => {
    const data = {
      sql_ref_no: "31",
      user_id: userId.toString(),
      customer_id: customerId.toString(),
      verification_code: verificationCode,
      option: "1",
    };

    dispatch(getWalletBalance(data))
      .unwrap()
      .then(() => {
        const id = customerId?.toString();
        dispatch(getTransactions(id));
      })
      .catch((err) => err);
  };

  const goBack = () => {
    setOpenPaymentSummaryModal(false);
    setOpenPurchaseSummaryModal(true);
  };

  const handleCreatePinSubmit = async (e) => {
    e.preventDefault();

    try {
      const toastId = toast.loading("Submitting your pin...");

      const payload = {
        sql_ref_no: "10",
        user_id: userId?.toString(),
        verification_code: verificationCode.toString(),
        pin_number: createPinOTP,
        option: "11",
      };

      dispatch(createTransactionPin(payload))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            return toast.error("Pin could not be submitted!", {
              id: toastId,
            });
          }

          setOpenCreateotp(false);

          return toast.success("Transaction Pin set", {
            id: toastId,
          });
        })
        .catch((err) => {
          setOtp("");

          toast.error("Transaction Pin could not be set!", {
            id: toastId,
          });

          navigate("/login");
          return err;
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <div className="mb-6 px-2 sm:px-6">
        <h2 className="mt-6 text-xl font-bold text-gray-700">
          Pay With Wallet
        </h2>
        <div className="mt-6 rounded-md border-[1px] border-gray-200 bg-[#D5EBBB] p-4">
          <h4 className="text-xs font-medium text-gray-500">
            You are paying for:
          </h4>
          <p className="mt-2 text-xl font-bold text-gray-800">
            {fetchedCreateInfo &&
            fetchedCreateInfo?.data?.item_price &&
            allProducts?.gropool_delivery_charge?.length
              ? CurrencyComponent(
                  +fetchedCreateInfo?.data?.item_price +
                    allProducts?.gropool_delivery_charge[0]?.delivery_charge
                )
              : null}
          </p>
          <p className="mt-1 text-sm font-normal text-gray-700">
            {`${
              fetchedCreateInfo && fetchedCreateInfo?.data?.purchase_slot
                ? fetchedCreateInfo?.data?.purchase_slot
                : null
            } slots of ${
              fetchedCreateInfo &&
              fetchedCreateInfo?.selectedItemObj?.name &&
              fetchedCreateInfo?.data?.slot_number
                ? `${fetchedCreateInfo?.selectedItemObj?.name.split(",")[0]}`
                : `${fetchedCreateInfo?.selectedItemObj?.item_name}`
            } share to be shared ${
              fetchedCreateInfo && fetchedCreateInfo?.data?.gropool_date
                ? moment(fetchedCreateInfo?.data?.gropool_date).format("LL")
                : null
            }`}
          </p>
        </div>
        <p className="mt-2 text-lg font-semibold text-gray-700">
          Please input your transaction PIN
        </p>

        {fetchedStatuses && fetchedStatuses?.transaction_pin === "True" ? (
          <p
            onClick={() => setOpenForgototp(true)}
            className="mt-1 flex cursor-pointer flex-row text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
          >
            Forgot Transaction pin?
            <span className="cursor-pointer">
              <AiOutlineQuestionCircle className="ml-1 mt-1 h-4 w-4 text-olivine-600" />
            </span>
          </p>
        ) : (
          <p
            onClick={() => setOpenCreateotp(true)}
            className="mt-1 flex cursor-pointer flex-row text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
          >
            Set-up Transaction pin
            <span className="cursor-pointer">
              <AiOutlineQuestionCircle className="ml-1 mt-1 h-4 w-4 text-olivine-600" />
            </span>
          </p>
        )}

        <div className="mt-1 flex justify-center">
          <OtpInput
            inputStyle={{
              margin: 2,
              height: 34,
              width: 34,
              borderRadius: 4,
              borderColor: "#BDBDBD",
              outline: "none",
              border: "1px solid #BDBDBD",
            }}
            numInputs={4}
            value={otp}
            onChange={setOtp}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <div className="mt-8 flex items-center justify-between space-x-8 md:space-x-16">
          <button
            onClick={() => goBack()}
            className={`w-full transform rounded-md border-[1px] border-gray-300 px-10 py-4 text-xs font-semibold text-gray-600 transition-all duration-200 hover:bg-gray-100 focus:outline-none xsm:text-sm`}
          >
            Back
          </button>
          <button
            onClick={() =>
              fetchedCreateInfo?.isUpdate
                ? handleUpdateSubmit()
                : handleSubmit()
            }
            className={`w-full transform rounded-md px-10 py-4 text-xs font-semibold text-white transition-all duration-200 focus:outline-none xsm:text-sm ${
              otp?.length === 4
                ? "cursor-pointer bg-olivine-500 hover:bg-olivine-500/80 active:bg-olivine-600"
                : "cursor-disabled cursor-not-allowed bg-gray-300"
            }`}
            disabled={otp?.length < 4}
          >
            Proceed
          </button>
        </div>
      </div>
      <Transition appear show={openCreateotp} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setOpenPaymentSuccessfulModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                    <div
                      onClick={() => setOpenCreateotp(!openCreateotp)}
                      className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                    >
                      <IoClose className="h-5 w-5" />
                    </div>
                    <div className="mb-6 px-2 sm:px-6">
                      <div className="flex flex-col items-center justify-center space-x-2">
                        <h2 className="mt-10 text-xl font-semibold text-gray-700">
                          OTP
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                          Set new transaction PIN
                        </p>
                        <div className="mt-4 flex justify-center">
                          <div>
                            <label className="max-w-sm pl-2 text-start text-xs text-gray-600">
                              Input New PIN
                            </label>
                            <OtpInput
                              inputStyle={{
                                margin: 3,
                                height: 36,
                                width: 36,
                                borderRadius: 4,
                                borderColor: "#BDBDBD",
                                outline: "none",
                                border: "1px solid #BDBDBD",
                              }}
                              value={createPinOTP}
                              onChange={setCreatePinOTP}
                              numInputs={4}
                              inputType="number"
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                        </div>
                        <div className="mt-2 flex justify-center">
                          <div>
                            <label className="max-w-sm pl-2 text-start text-xs text-gray-600">
                              Confirm New PIN
                            </label>
                            <OtpInput
                              inputStyle={{
                                margin: 3,
                                height: 35,
                                width: 35,
                                borderRadius: 4,
                                borderColor: "#BDBDBD",
                                outline: "none",
                                border: "1px solid #BDBDBD",
                              }}
                              value={createPinOTP2}
                              onChange={setCreatePinOTP2}
                              numInputs={4}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                        </div>
                        <div className="mx-auto mb-12 mt-6 w-full max-w-[250px]">
                          <button
                            onClick={handleCreatePinSubmit}
                            className={`w-full transform rounded-md px-10 py-4 text-xs font-semibold text-white transition-all duration-200 focus:outline-none xsm:text-sm ${
                              createPinOTP === createPinOTP2
                                ? "cursor-pointer bg-olivine-500 hover:bg-olivine-500/80 active:bg-olivine-600"
                                : "cursor-disabled cursor-not-allowed bg-gray-400"
                            }`}
                            disabled={!createPinOTP || !createPinOTP2}
                          >
                            Save new pin
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={openForgototp} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setOpenForgototp(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="scrollbar-thumb-[#1a3517] scrollbar-track-[#265426] h-full max-h-[525px] overflow-y-scroll p-2 scrollbar-thin">
                    <div
                      onClick={() => setOpenForgototp(!openForgototp)}
                      className="fixed right-4 top-2 w-fit cursor-pointer rounded-full bg-[#558223] p-1.5 text-white"
                    >
                      <IoClose className="h-5 w-5" />
                    </div>
                    <div>
                      <ForgotTransactionPinPopup
                        setOpenForgototp={setOpenForgototp}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PaymentCheckoutPopup;
