import { createAsyncThunk } from "@reduxjs/toolkit";
import { startLoad, stopLoad } from "../slices/appSlice";
import { handleError } from "../slices/errorSlice";
import ProductSharingService from "../../services/productSharingService";

export const fetchHowItWorks = createAsyncThunk(
  "get/fetchHowItWorks",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.howItWorks();

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchFAQ = createAsyncThunk(
  "get/fetchFAQ",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.faq();

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const fetchProducts = createAsyncThunk(
  "get/fetchProducts",
  async (data, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.products(data);

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const handleCreateProduct = createAsyncThunk(
  "post/handleCreateProduct",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.createProduct(
        payload.data,
        payload.customerId
      );

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const handleUpdateProduct = createAsyncThunk(
  "post/handleUpdateProduct",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.updateProduct(
        payload.data,
        payload.customerId
      );

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "get/getAllProducts",
  async (_, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.handleProductSharingProducts();

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getAllCustomerProducts = createAsyncThunk(
  "get/getAllCustomerProducts",
  async (customerId, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res =
        await ProductSharingService.handleProductSharingProductsCustomer(
          customerId
        );

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "get/getSingleProduct",
  async (refNo, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.handleProductSharingSingle(refNo);

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const addNewReceipient = createAsyncThunk(
  "post/addNewReceipient",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.handleAddNewReceipient(
        payload.customerId,
        payload.data
      );

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const updateReceipient = createAsyncThunk(
  "post/updateReceipient",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.handleUpdateReceipient(
        payload.receipientRefNo,
        payload.data
      );

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getAllGiftReceipients = createAsyncThunk(
  "get/getAllGiftReceipients",
  async (customerId, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.handleGetAllGiftReciepients(
        customerId
      );

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getSingleGiftReceipient = createAsyncThunk(
  "get/getSingleGiftReceipient",
  async (receipientRefNo, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.handleGetSingleGiftReciepient(
        receipientRefNo
      );

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getProductShareHistory = createAsyncThunk(
  "post/getProductShareHistory",
  async (payload, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res = await ProductSharingService.handleGetProductSharingHistory(
        payload.customerId,
        payload.data
      );

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);

export const getProductShareHistorySingle = createAsyncThunk(
  "post/getProductShareHistorySingle",
  async (refNo, { dispatch }) => {
    dispatch(startLoad());

    try {
      const res =
        await ProductSharingService.handleGetProductSharingHistorySingle(refNo);

      return res?.data || res;
    } catch (err) {
      handleError(err, dispatch);
    } finally {
      dispatch(stopLoad());
    }
  }
);
