import baseAxios from "../core/api/axios/baseAxios";

class ProductSharingService {
  static async howItWorks() {
    const res = await baseAxios.get("product-sharing/how-it-works");

    return res?.data || res;
  }

  static async faq() {
    const res = await baseAxios.get("product-sharing/faq");

    return res?.data || res;
  }

  static async products(customerId) {
    const res = await baseAxios.get(
      `product-sharing/get-products/${customerId}`
    );

    return res?.data || res;
  }

  static async createProduct(data, customerId) {
    const res = await baseAxios.post(
      `product-sharing/create-product/${customerId}`,
      data
    );

    return res?.data || res;
  }

  static async updateProduct(data, customerId) {
    const res = await baseAxios.post(
      `product-sharing/update-product/${customerId}`,
      data
    );

    return res?.data || res;
  }

  static async handleProductSharingProducts() {
    const res = await baseAxios.get("product-sharing/product-sharing-all");

    return res?.data || res;
  }

  static async handleProductSharingProductsCustomer(customerId) {
    const res = await baseAxios.get(
      `product-sharing/product-sharing-all/${customerId}`
    );

    return res?.data || res;
  }

  static async handleProductSharingSingle(ref_no) {
    const res = await baseAxios.get(
      `product-sharing/product-sharing-single/${ref_no}`
    );

    return res?.data || res;
  }

  static async handleAddNewReceipient(customerId, data) {
    const res = await baseAxios.post(
      `product-sharing/new-gift-receipient/${customerId}`,
      data
    );

    return res?.data || res;
  }

  static async handleUpdateReceipient(receipientRefNo, data) {
    const res = await baseAxios.post(
      `product-sharing/update-gift-receipient/${receipientRefNo}`,
      data
    );

    return res?.data || res;
  }

  static async handleGetAllGiftReciepients(customerId) {
    const res = await baseAxios.get(
      `product-sharing/all-gift-receipient-info/${customerId}`
    );

    return res?.data || res;
  }

  static async handleGetSingleGiftReciepient(receipientRefNo) {
    const res = await baseAxios.get(
      `product-sharing/single-gift-receipient-info/${receipientRefNo}`
    );

    return res?.data || res;
  }

  static async handleGetProductSharingHistory(customerId, data) {
    const res = await baseAxios.post(
      `product-sharing/product-sharing-history/${customerId}`,
      data
    );

    return res?.data || res;
  }

  static async handleGetProductSharingHistorySingle(refNo) {
    const res = await baseAxios.get(
      `product-sharing/product-sharing-history/single/${refNo}`
    );

    return res?.data || res;
  }
}

export default ProductSharingService;
