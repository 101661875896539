import React, { useEffect, useState } from "react";
import { CurrencyComponent } from "../../../core/helpers";
import moment from "moment";
import { authService } from "../../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../../../store/actions";

const PurchaseSummaryPopup = ({
  fetchedCreateInfo,
  setOpenPaymentSummaryModal,
  setOpenPurchaseSummaryModal,
  setOpenSelectSlotModal,
  setOpenRecipientDetailsModal,
  isUpdate,
  isGift,
}) => {
  const dispatch = useDispatch();
  const [isNotifyChecked, setIsNotifyChecked] = useState(false);

  const userEmail = authService.getCurrentUserEmail();
  const userPhone = authService.getCurrentUserPhone();

  const { allProducts } = useSelector((state) => state.productShare);

  useEffect(() => {
    if (!allProducts?.gropool_delivery_charge?.length) {
      handleProductSharingAll();
    }
  }, []);

  const handleProductSharingAll = async () => {
    try {
      dispatch(getAllProducts());
    } catch (err) {
      return err;
    }
  };

  const handleProceed = () => {
    setOpenPurchaseSummaryModal(false);
    setOpenPaymentSummaryModal(true);
  };

  const goBack = () => {
    setOpenPurchaseSummaryModal(false);
    if (isUpdate && isGift) setOpenRecipientDetailsModal(true);
    setOpenSelectSlotModal(true);
  };

  return (
    <div className="mb-8 px-2 @container sm:px-6">
      <h2 className="mt-6 text-xl font-semibold text-gray-700">
        Purchase Summary
      </h2>
      <div className="mt-2 divide-y-[1px] divide-gray-200">
        <div className="py-2">
          <p className="text-xs font-medium text-gray-500">Product Name</p>
          <p className="mt-1 text-sm font-semibold text-gray-700">
            {fetchedCreateInfo &&
            fetchedCreateInfo?.selectedItemObj?.name &&
            fetchedCreateInfo?.data?.slot_number
              ? `${fetchedCreateInfo?.selectedItemObj?.name.split(",")[0]} x ${
                  fetchedCreateInfo?.data?.slot_number
                }`
              : null}
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">
            Slot Information
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slots Purchased by you{" "}
            <span className="text-sm font-normal text-gray-500">
              {fetchedCreateInfo && fetchedCreateInfo?.data?.purchase_slot
                ? fetchedCreateInfo?.data?.purchase_slot
                : null}
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Price Per Slot:{" "}
            <span className="text-sm font-normal text-gray-500">
              {fetchedCreateInfo && fetchedCreateInfo?.data?.price_per_slot
                ? CurrencyComponent(fetchedCreateInfo?.data?.price_per_slot)
                : null}
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Delivery Fee:{" "}
            <span className="text-sm font-normal text-gray-500">
              {allProducts && allProducts?.gropool_delivery_charge?.length
                ? CurrencyComponent(
                    allProducts?.gropool_delivery_charge[0]?.delivery_charge
                  )
                : null}
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Total Amount for Purchase:{" "}
            <span className="text-sm font-normal text-gray-500">
              {fetchedCreateInfo &&
              fetchedCreateInfo?.data?.item_price &&
              allProducts?.gropool_delivery_charge?.length
                ? CurrencyComponent(
                    +fetchedCreateInfo?.data?.item_price +
                      allProducts?.gropool_delivery_charge[0]?.delivery_charge
                  )
                : null}
            </span>
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">Purchase Type</p>
          {fetchedCreateInfo &&
          fetchedCreateInfo?.purchasingFor &&
          fetchedCreateInfo?.purchasingFor === "myself" ? (
            <p className="mt-1 text-xs font-medium text-gray-700">
              Purchase Option:{" "}
              <span className="text-sm font-normal text-gray-500">
                Purchase For self
              </span>
            </p>
          ) : (
            <p className="mt-1 text-xs font-medium text-gray-700">
              Purchase Option:{" "}
              <span
                onClick={() => goBack()}
                className="cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4"
              >
                Gift-Edit Recipients Details
              </span>
            </p>
          )}
          {/* <p className="mt-1 text-xs font-medium text-gray-700">
            Purchase Option:{" "}
            <span className="cursor-pointer text-sm font-normal text-olivine-600 hover:underline hover:underline-offset-4">
              Gift-Edit Recipients Details
            </span>
          </p>
          <p className="my-1 text-base font-bold text-olivine-600">OR</p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Purchase Option:{" "}
            <span className="text-sm font-normal text-gray-500">
              Purchase For self
            </span>
          </p> */}
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">
            Slot status at time of purchase
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Total Slots{" "}
            <span className="text-sm font-normal text-gray-500">
              {fetchedCreateInfo && fetchedCreateInfo?.data?.slot_number
                ? fetchedCreateInfo?.data?.slot_number
                : null}
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slots Filled:{" "}
            <span className="text-sm font-normal text-gray-500">
              {fetchedCreateInfo && fetchedCreateInfo?.data?.purchase_slot
                ? fetchedCreateInfo?.data?.purchase_slot
                : null}
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slots yet to be filled:{" "}
            <span className="text-sm font-normal text-gray-500">
              {fetchedCreateInfo &&
              fetchedCreateInfo?.data?.purchase_slot &&
              fetchedCreateInfo?.data?.slot_number
                ? +fetchedCreateInfo?.data?.slot_number -
                  Number(fetchedCreateInfo?.data?.purchase_slot)
                : null}
            </span>
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">
            Deadline Information
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slot Filling Deadline:{" "}
            <span className="text-sm font-normal text-gray-500">
              {fetchedCreateInfo && fetchedCreateInfo?.data?.gropool_date
                ? moment(fetchedCreateInfo?.data?.gropool_date).format("LL")
                : null}
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Slot Deadline Option:{" "}
            <span className="text-sm font-normal text-gray-500">
              Split Remaining slots among buyers
            </span>
          </p>
        </div>
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-500">
            Notifications and Updates
          </p>
          <p className="text-[11px] font-semibold text-gray-500">
            where the user will receive updates about the slot filling progress
            or additional payments.
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Email Address{" "}
            <span className="text-sm font-normal text-gray-500">
              {userEmail ? userEmail : ""}
            </span>
          </p>
          <p className="mt-1 text-xs font-medium text-gray-700">
            Phone Number{" "}
            <span className="text-sm font-normal text-gray-500">
              {userPhone ? userPhone : ""}
            </span>
          </p>
        </div>
      </div>
      <div className="mt-4 rounded-md border-[1px] border-gray-200 bg-[#EDEDED] p-3">
        <h3 className="text-sm font-semibold text-gray-700">Policy Reminder</h3>
        <p className="mt-1 text-xs font-normal text-gray-500">
          If all slots are not filled by the deadline (1 day to the sharing
          date), you will be asked to cover the extra cost with other buyers
        </p>
        <div className="mt-2 flex flex-row items-center">
          <input
            type="checkbox"
            checked={isNotifyChecked}
            onChange={(e) => setIsNotifyChecked(e.target.checked)}
            className="h-4 w-4 cursor-pointer rounded border-[1px] border-gray-300 checked:text-[#558223] checked:ring-0 checked:ring-[#558223] focus:ring-0 focus:ring-[#558223]"
          />
          <span className="ml-1 text-xs  text-gray-600">
            I agree with the{" "}
            <span className="cursor-pointer font-medium text-[#558223] hover:underline hover:underline-offset-4">
              Terms and Conditions
            </span>
          </span>
        </div>
      </div>
      <div className="mt-10 flex flex-col @sm:flex-row-reverse @sm:items-center @sm:justify-between">
        <button
          onClick={() => handleProceed()}
          className={`w-full transform rounded-md px-4 py-3.5 text-sm font-medium text-white transition-all duration-200 focus:outline-none @sm:max-w-[180px] ${
            isNotifyChecked
              ? "cursor-pointer bg-[#558223] hover:bg-olivine-500/80 active:bg-olivine-600"
              : "cursor-disabled cursor-not-allowed bg-gray-400"
          }`}
          disabled={!isNotifyChecked}
        >
          Proceed
        </button>
        <button
          onClick={() => goBack()}
          className="mt-4 w-full transform cursor-pointer rounded-md border-[1px] border-gray-300 bg-white px-4 py-3.5 text-sm font-medium text-gray-600 transition-all duration-200 focus:outline-none @sm:mt-0 @sm:max-w-[180px]"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default PurchaseSummaryPopup;
