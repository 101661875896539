import React, { useEffect, useState } from "react";
import SignedInHomeCardComponent from "./SignedInHomeCardComponent";
import SignedInNavbarImproved from "./SignedInNavbarImproved";
import { authService } from "../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { activePlans, todoListStatus } from "../store/actions";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { CgClose } from "react-icons/cg";
import {
  setBasketSalesOrder,
  setIsPastLogin,
  setSalesOrder,
  setSelectedProductName,
} from "../store/slices/modalSlice";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import CreateBasketIcon from "../assets/createBasketIcon.svg";
import GiftBasketIcon from "../assets/giftBasketIcon.svg";
import ButchersIcon from "../assets/butchersIcon.svg";
import NoSubscriptionPlanImage from "./../assets/noSubscriptionPlanImage.svg";
import IsLoadingFour from "./IsloadingFour";
import toast from "react-hot-toast";
import HomeBannerSlider from "./BannerSlider/HomeBannerSlider";
import { BsInfo } from "react-icons/bs";
import Modal from "./Ui";
import { Tab } from "@headlessui/react";
import Container from "./pages/my_container/Container";
import GropoolOrders from "./pages/gropool/GropoolOrders";

const plans = [
  { name: "Active Subscription" },
  { name: "All Orders" },
  { name: "Cancelled Subscription" },
  { name: "Gift Orders" },
  { name: "Custom Orders" },
];

function SignedInHome() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [fetchedActivePlans, setFetchedActivePlans] = useState([]);
  const [fetchedActivePlansImages, setFetchedActivePlansImages] = useState([]);
  const [fetchedActiveSalesOrder, setFetchedActiveSalesOrder] = useState([]);
  const [filteredBasketPlans, setFilteredBasketPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const { activePlansCustomer, todoListStatusData } = useSelector(
    (state) => state.masterData
  );
  const [selected, setSelected] = useState(plans[0]);
  const [fetchedActiveCreateBasket, setFetchedActiveCreateBasket] = useState(
    []
  );
  const [statusPercent, setStatusPercent] = useState("");
  const [fetchedStatuses, setfetchedStatuses] = useState("");

  const customerId = authService.getCurrentUserZohoCustomerId();
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const corporateUserVerificationStatusId = authService.getApprovedStatusId();
  const idRejected = authService.getRejectionReason();
  const customertype = authService.getAccountType();
  const approvedBannerId = authService.getApprovedBannerStatusId();

  useEffect(() => {
    dispatch(setIsPastLogin(true));
  }, [dispatch]);

  useEffect(() => {
    if (!todoListStatusData) {
      const data = {
        sql_ref_no: "20",
        user_id: userId.toString(),
        verification_code: verification_code?.toString(),
        option: "1",
      };

      dispatch(todoListStatus(data));
    }

    if (todoListStatusData) setfetchedStatuses(todoListStatusData);
  }, [dispatch, todoListStatusData, verification_code, userId]);

  useEffect(() => {
    if (fetchedStatuses) {
      const results = [];
      for (let key in fetchedStatuses) {
        results.push(fetchedStatuses[key]);
      }

      // Return the number of times an array element appears in the array
      const getOccurrence = (array, value) => {
        return array.filter((v) => v === value).length;
      };

      const getPercentage = (array, occ) => {
        return Math.round((occ / array.length) * 100);
      };

      const yesOcc = getOccurrence(results, "True");

      const yesPercent = getPercentage(results, yesOcc);

      setStatusPercent(yesPercent);
    }
  }, [fetchedStatuses]);

  useEffect(() => {
    try {
      if (!activePlansCustomer) {
        setIsLoading(true);
        dispatch(activePlans(customerId))
          .unwrap()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
      }
    } catch (err) {
      return err;
    }
  }, [dispatch, customerId, activePlansCustomer]);

  useEffect(() => {
    try {
      if (activePlansCustomer) {
        setFetchedActivePlans(activePlansCustomer?.subscription_list);
        setFetchedActivePlansImages(activePlansCustomer?.plan_image);
        setFetchedActiveSalesOrder(activePlansCustomer?.salesorder_list);
      }
    } catch (err) {
      return err;
    }
  }, [activePlansCustomer]);

  useEffect(() => {
    if (activePlansCustomer?.salesorder_list?.length) {
      const createBasketPlans = activePlansCustomer?.salesorder_list?.filter(
        (faso) =>
          !faso?.subscription_id &&
          (faso?.salesorder_typeid === "1" || faso?.salesorder_typeid === "2")
      );

      setFilteredBasketPlans(createBasketPlans);

      setFetchedActiveCreateBasket(createBasketPlans);
    }
  }, [activePlansCustomer?.salesorder_list]);

  useEffect(() => {
    try {
      if (activePlansCustomer && selected) {
        switch (selected?.name) {
          case "All Orders":
            setFetchedActivePlans(activePlansCustomer?.subscription_list);
            setFetchedActiveCreateBasket(filteredBasketPlans);
            break;
          case "Active Subscription":
            const active = activePlansCustomer?.subscription_list?.filter(
              (actiPlans) => actiPlans?.status === "live"
            );

            setFetchedActivePlans(active);
            setFetchedActiveCreateBasket([]);
            break;
          case "Paused Plans":
            const paused = activePlansCustomer?.subscription_list?.filter(
              (actiPlans) => actiPlans?.status === "paused"
            );

            setFetchedActivePlans(paused);
            setFetchedActiveCreateBasket([]);
            break;
          case "Cancelled Subscription":
            const canceled = activePlansCustomer?.subscription_list?.filter(
              (actiPlans) => actiPlans?.status === "cancelled"
            );

            setFetchedActivePlans(canceled);
            setFetchedActiveCreateBasket([]);
            break;
          case "Gift Orders":
            const gifts = activePlansCustomer?.subscription_list?.filter(
              (actiPlans) => actiPlans?.is_gift === "true"
            );

            setFetchedActivePlans(gifts);
            setFetchedActiveCreateBasket([]);
            break;
          case "Custom Orders":
            setFetchedActivePlans([]);
            setFetchedActiveCreateBasket(filteredBasketPlans);
            break;
          default:
            setFetchedActivePlans(activePlansCustomer?.subscription_list);
            setFetchedActiveCreateBasket(filteredBasketPlans);
        }
      }
    } catch (err) {
      return err;
    }
  }, [selected, activePlansCustomer, filteredBasketPlans]);

  const handleTrack = (product) => {
    const saleOrderTrack = fetchedActiveSalesOrder?.filter(
      (saleOrder) => saleOrder?.subscription_id === product?.subscription_id
    );

    if (saleOrderTrack?.length) {
      dispatch(setSalesOrder(saleOrderTrack));
      dispatch(
        setSelectedProductName({
          planName: product?.name,
          planPrice: product?.amount,
          planDuration: `${moment(product?.current_term_starts_at).format(
            "MMMM YYYY"
          )} - ${moment(product?.current_term_ends_at).format("MMMM YYYY")}`,
          symbol: product?.currency_symbol,
        })
      );
      navigate(`/track/${product?.subscription_id}`);
    }

    if (!product?.subscription_id) {
      dispatch(setBasketSalesOrder(product));
      dispatch(
        setSelectedProductName({
          planName: product?.adjustment_description,
          planPrice: product?.total_amount,
          planDuration: `${moment(product?.start_date).format("MMMM YYYY")}`,
          symbol: product?.currency_symbol,
        })
      );
      navigate(`/track/create/${product?.salesorder_number}`);
    }

    if (!saleOrderTrack?.length) {
      return toast.success("Your order is being processed");
    }
  };

  const handleRedirect = (location) => {
    setShowModal(true);

    setSelectedModal(location);
  };

  const handleOnClose = () => {
    setShowModal(false);
    setSelectedModal("");
  };

  return (
    <div className="bg-[#F5F5F5] pb-10">
      <SignedInNavbarImproved />
      <Container className="pt-6">
        {corporateUserVerificationStatusId === 2 &&
          fetchedStatuses.customer_corporate_id_upload_url_status === "False" &&
          customertype === "Corporate" && (
            <div className="mb-2 space-y-1 rounded-md border-[1px] border-gray-100 bg-white px-4 py-3 sm:px-6 md:flex md:flex-row md:items-center md:justify-between md:space-x-6 md:space-y-0">
              <div className="flex flex-col space-y-1 md:flex-row md:items-start md:space-x-1 md:space-y-0">
                <div className="flex">
                  <div className="rounded-full bg-olivine-600 text-white md:mt-[2px]">
                    <BsInfo className="h-4 w-4" />
                  </div>
                </div>
                <p className="text-sm font-medium text-gray-700">
                  Please upload your company's ID card for account verification
                  to access the benefits. Thank you.
                </p>
              </div>
              <p
                onClick={() => handleRedirect("addId")}
                className="cursor-pointer text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
              >
                Upload Company’s ID Card
              </p>
            </div>
          )}

        {corporateUserVerificationStatusId === 2 &&
          fetchedStatuses.customer_corporate_id_upload_url_status === "True" &&
          customertype === "Corporate" && (
            <div className="mb-2 space-y-1 rounded-md border-[1px] border-gray-100 bg-white px-4 py-3 sm:px-6 md:flex md:flex-row md:items-center md:justify-between md:space-x-6 md:space-y-0">
              <div className="flex flex-col space-y-1 md:flex-row md:items-start md:space-x-1 md:space-y-0">
                <div className="flex">
                  <div className="rounded-full bg-olivine-600 text-white md:mt-[2px]">
                    <BsInfo className="h-4 w-4" />
                  </div>
                </div>
                <p className="text-sm font-medium text-gray-700">
                  Your Account is under review. We will notify you promptly once
                  the approval process is complete.
                </p>
              </div>
              <Link to="/home/todo">
                <p className="cursor-pointer text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2">
                  Go to To-do List
                </p>
              </Link>
            </div>
          )}

        {corporateUserVerificationStatusId === 1 &&
          customertype === "Corporate" &&
          approvedBannerId === 1 && (
            <div className="mb-2 space-y-1 rounded-md border-[1px] border-gray-100 bg-white px-4 py-3 sm:px-6 md:flex md:flex-row md:items-center md:justify-between md:space-x-6 md:space-y-0">
              <div className="flex flex-col space-y-1 md:flex-row md:items-start md:space-x-1 md:space-y-0">
                <div className="flex">
                  <div className="rounded-full bg-olivine-600 text-white md:mt-[2px]">
                    <BsInfo className="h-4 w-4" />
                  </div>
                </div>
                <p className="text-sm font-medium text-gray-700">
                  Congratulations 🎉 your account has been approved!
                </p>
              </div>
            </div>
          )}

        {corporateUserVerificationStatusId === 3 &&
          customertype === "Corporate" &&
          idRejected && (
            <div className="mb-2 space-y-1 rounded-md border-[1px] border-gray-100 bg-white px-4 py-3 sm:px-6 md:flex md:flex-row md:items-center md:justify-between md:space-x-6 md:space-y-0">
              <div className="flex flex-col space-y-1 md:flex-row md:items-start md:space-x-1 md:space-y-0">
                <div className="flex">
                  <div className="rounded-full bg-olivine-600 text-white md:mt-[2px]">
                    <BsInfo className="h-4 w-4" />
                  </div>
                </div>
                <p className="text-sm font-medium text-gray-700">
                  {`Declined: ${idRejected}`}
                </p>
              </div>
              <p
                onClick={() => handleRedirect("addId")}
                className="cursor-pointer text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
              >
                Re-Upload Company’s ID Card
              </p>
            </div>
          )}

        {corporateUserVerificationStatusId === 4 &&
          customertype === "Corporate" && (
            <div className="mb-2 space-y-1 rounded-md border-[1px] border-gray-100 bg-white px-4 py-3 sm:px-6 md:flex md:flex-row md:items-center md:justify-between md:space-x-6 md:space-y-0">
              <div className="flex flex-col space-y-1 md:flex-row md:items-start md:space-x-1 md:space-y-0">
                <div className="flex">
                  <div className="rounded-full bg-olivine-600 text-white md:mt-[2px]">
                    <BsInfo className="h-4 w-4" />
                  </div>
                </div>
                <p className="text-sm font-medium text-gray-700">
                  Please Resend your company ID for review.
                </p>
              </div>
              <p
                onClick={() => handleRedirect("addId")}
                className="cursor-pointer text-sm font-medium text-olivine-600 hover:underline hover:underline-offset-2"
              >
                Re-Upload Company’s ID Card
              </p>
            </div>
          )}

        <div>
          <div className="text-2xl font-bold text-gray-600">Home</div>
          {fetchedStatuses?.user_profile === "True" &&
          fetchedStatuses?.phone_number === "True" &&
          fetchedStatuses?.transaction_pin === "True" &&
          fetchedStatuses?.delivery_address === "True" &&
          !isHidden ? null : (
            <div className="h-hug mt-4 w-full rounded-md border-[0.5px] border-gray-300 bg-[#E9F8CC] px-6 py-4">
              <div className="flex flex-row justify-between">
                <h4 className="text-xs font-semibold text-gray-600">
                  Your profile progress
                </h4>
                <div className="cursor-pointer rounded-full bg-[#F6FBEE] p-1 active:bg-gray-200">
                  <CgClose
                    onClick={() => setIsHidden(!isHidden)}
                    className="h-3 w-3 text-gray-600"
                  />
                </div>
              </div>
              <div className="mt-1 sm:flex sm:gap-8 sm:pr-6 lg:gap-16 lg:pr-10">
                <div className="flex-1">
                  <div className="text-base font-bold text-gravel-500">
                    {statusPercent ? `${statusPercent}% Complete` : "0%"}
                  </div>
                  <div className="relative mt-1">
                    <div className="h-2 w-full rounded-full bg-white"></div>
                    <div
                      style={{ width: statusPercent + "%" }}
                      className="absolute top-0 h-2 rounded-full bg-olivine-500"
                    ></div>
                  </div>
                </div>
                <Link to="/home/todo">
                  <button className="mt-6 w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-2.5 text-xs font-semibold text-olivine-500 transition-all duration-200 hover:-translate-y-0.5 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:text-sm sm:mt-0 sm:w-auto sm:px-8 sm:py-[11px]">
                    Complete profile
                  </button>
                </Link>
              </div>
            </div>
          )}
          {/* {statusPercent && statusPercent !== 100 && !isHidden ? (
            <div className="h-hug mt-4 w-full rounded-md border-[0.5px] border-gray-300 bg-[#E9F8CC] px-6 py-4">
              <div className="flex flex-row justify-between">
                <h4 className="text-xs font-semibold text-gray-600">
                  Your profile progress
                </h4>
                <div className="cursor-pointer rounded-full bg-[#F6FBEE] p-1 active:bg-gray-200">
                  <CgClose
                    onClick={() => setIsHidden(!isHidden)}
                    className="h-3 w-3 text-gray-600"
                  />
                </div>
              </div>
              <div className="mt-1 sm:flex sm:gap-8 sm:pr-6 lg:gap-16 lg:pr-10">
                <div className="flex-1">
                  <div className="text-base font-bold text-gravel-500">
                    {statusPercent ? `${statusPercent}% Complete` : "0%"}
                  </div>
                  <div className="relative mt-1">
                    <div className="h-2 w-full rounded-full bg-white"></div>
                    <div
                      style={{ width: statusPercent + "%" }}
                      className="absolute top-0 h-2 rounded-full bg-olivine-500"
                    ></div>
                  </div>
                </div>
                <Link to="/home/todo">
                  <button className="mt-6 w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-2.5 text-xs font-semibold text-olivine-500 transition-all duration-200 hover:-translate-y-0.5 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:text-sm sm:mt-0 sm:w-auto sm:px-8 sm:py-[11px]">
                    Complete profile
                  </button>
                </Link>
              </div>
            </div>
          ) : null} */}
        </div>

        <div className="mt-10">
          <Tab.Group>
            {/*   Tab section header */}
            <Tab.List className="flex w-full flex-row items-center border-b-[1px] border-b-gray-200 text-lg">
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "mt-3 w-full flex-1 border-b-[1px] border-olivine-500 border-opacity-100 py-2 text-olivine-500 outline-none"
                        : "mt-3 w-full flex-1 border-b-[1px] border-olivine-500 border-opacity-0 py-2 text-gray-500"
                    }
                  >
                    <p className="cursor-pointer font-semibold">Your Plans</p>
                  </button>
                )}
              </Tab>

              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    className={
                      selected
                        ? "mt-3 w-full flex-1 border-b-[1px] border-olivine-500 border-opacity-100 py-2 text-olivine-500 outline-none"
                        : "mt-3 w-full flex-1 border-b-[1px] border-olivine-500 border-opacity-0 py-2 text-gray-500"
                    }
                  >
                    <p className="cursor-pointer font-semibold">
                      GroPool Orders
                    </p>
                  </button>
                )}
              </Tab>
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel>
                {" "}
                <div>
                  <div className="mt-8 xsm:flex xsm:items-center xsm:justify-between">
                    <div className="text-xl font-semibold text-gray-700">
                      Your plans
                    </div>
                    <div className="xsm:justify-star mt-4 flex items-baseline justify-end space-x-1 xsm:mt-0 xsm:space-x-2">
                      <div className="text-xl font-medium text-gray-600">
                        Sort:
                      </div>
                      <div className="">
                        <Listbox value={selected} onChange={setSelected}>
                          <div className="relative mt-1">
                            <div className="flex items-center space-x-1 xsm:space-x-2">
                              <div className="block truncate font-semibold text-gray-500">
                                {selected.name}
                              </div>
                              <Listbox.Button className="relative focus:outline-none">
                                <div className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200">
                                  <FiChevronDown
                                    className="h-6 w-6 text-olivine-500"
                                    aria-hidden="true"
                                  />
                                </div>
                              </Listbox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute right-0 z-50 mt-1 max-h-64 w-60 overflow-auto rounded-lg bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none xsm:w-64 sm:text-sm">
                                {plans.map((plan, planIdx) => (
                                  <Listbox.Option
                                    key={planIdx}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-3 pl-10 pr-4 ${
                                        active
                                          ? "bg-[#F5F5F5] font-semibold text-neutral-500"
                                          : "font-semibold text-gray-700"
                                      }`
                                    }
                                    value={plan}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {plan.name}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-olivine-500">
                                            <AiOutlineCheck
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 grid gap-4 grid-auto-fit-sm sm:grid-auto-fit-lg">
                    {fetchedActivePlans && fetchedActivePlans?.length
                      ? fetchedActivePlans?.map((activePlans, idx) => (
                          <SignedInHomeCardComponent
                            keyIdx={idx}
                            products={activePlans}
                            images={fetchedActivePlansImages}
                            tracker={handleTrack}
                          />
                        ))
                      : null}
                    {fetchedActiveCreateBasket &&
                    fetchedActiveCreateBasket?.length
                      ? fetchedActiveCreateBasket?.map(
                          (activeSalesPlans, idx) => (
                            <SignedInHomeCardComponent
                              keyIdx={idx}
                              products={activeSalesPlans}
                              images={fetchedActivePlansImages}
                              tracker={handleTrack}
                              customBasket
                            />
                          )
                        )
                      : null}
                    {isLoading ? (
                      <div className="col-span-full mt-8 flex w-full justify-center sm:mt-16">
                        <IsLoadingFour />
                      </div>
                    ) : null}
                    {!fetchedActivePlans &&
                    !fetchedActivePlans?.length &&
                    !fetchedActiveCreateBasket &&
                    !fetchedActiveCreateBasket?.length ? (
                      <div className="col-span-full flex w-full justify-center rounded-xl border-[0.5px] border-gray-200 shadow-xl">
                        <div className="flex w-full max-w-sm flex-col justify-center rounded-md p-8 shadow-gray-400">
                          <div className="mt-4 flex flex-col items-center justify-center">
                            <img
                              src={NoSubscriptionPlanImage}
                              alt="empty_photo"
                              className="h-28 w-auto"
                            />
                            <p className="-mt-8 text-sm font-semibold text-neutral-500">
                              You have no subscriptions
                            </p>
                          </div>
                          <div className="mt-4 flex items-center justify-center">
                            <button className="w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-olivine-500 p-8 py-3 text-sm font-semibold text-white  transition-all duration-200 hover:bg-olivine-400 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-600">
                              Subscribe to a plan
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="mt-10">
                    <h4 className="text-base font-semibold text-gray-600">
                      Check out our other products
                    </h4>
                    <div className="grid gap-6 grid-auto-fit-sm sm:grid-auto-fit-lg">
                      <div className="mt-4 flex w-full max-w-md flex-col justify-between rounded-lg border-[0.5px] border-gray-300 bg-[#E9F8CC] p-6 text-gray-600">
                        <div>
                          <div className="flex justify-start">
                            <div className="rounded-full bg-[#F6FBEE] p-3">
                              <img
                                src={CreateBasketIcon}
                                alt="icon"
                                className="h-6 w-6"
                              />
                            </div>
                          </div>
                          <h3 className="mt-2 text-xl font-bold">
                            Create your own basket:
                          </h3>
                          <h4 className="mt-1 text-xs font-bold">
                            From ₦15,000/month
                          </h4>
                          <p className="mt-2 text-sm">
                            With our customizable basket feature, you can shop
                            and personalize your basket by selecting individual
                            food items that suit your needs and preferences. Say
                            goodbye to pre-made curated plans and enjoy a
                            shopping experience tailored just for you!
                          </p>
                        </div>
                        <button
                          onClick={() => navigate("/basket/create-basket")}
                          className="mt-8 w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-2.5 text-sm font-semibold text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
                        >
                          Explore Now
                        </button>
                      </div>
                      <div className="mt-4 flex w-full max-w-md flex-col justify-between rounded-lg border-[0.5px] border-gray-300 bg-[#E9F8CC] p-6 text-gray-600">
                        <div>
                          <div className="flex justify-start">
                            <div className="rounded-full bg-[#F6FBEE] p-3">
                              <img
                                src={GiftBasketIcon}
                                alt="icon"
                                className="h-6 w-6"
                              />
                            </div>
                          </div>
                          <h3 className="mt-2 text-xl font-bold">
                            Gift a Basket
                          </h3>
                          <p className="mt-2 text-sm">
                            Easily send a curated gift basket to your loved
                            ones. Just select the items, provide the delivery
                            address, and we'll take care of the rest. Spread joy
                            and show you care, hassle-free!
                          </p>
                        </div>
                        <button
                          onClick={() => navigate("/gift-a-plan")}
                          className="mt-8 w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-2.5 text-sm font-semibold text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
                        >
                          Explore Now
                        </button>
                      </div>
                      <div className="mt-4 flex w-full max-w-md flex-col justify-between rounded-lg border-[0.5px] border-gray-300 bg-[#E9F8CC] p-6 text-gray-600">
                        <div>
                          <div className="flex justify-start">
                            <div className="rounded-full bg-[#F6FBEE] p-3">
                              <img
                                src={ButchersIcon}
                                alt="icon"
                                className="h-6 w-6"
                              />
                            </div>
                          </div>
                          <h3 className="mt-2 text-xl font-bold">GroChill</h3>
                          <p className="mt-2 text-sm">
                            Shop for a variety of frozen items with guaranteed
                            quality and freshness. Soon, we'll be adding more
                            frozen items like fish, goat, ram and frozen chips.
                            Shop confidently knowing you'll receive top-notch
                            products delivered in a cold chain to your doorstep!
                          </p>
                        </div>
                        <button
                          onClick={() => navigate("/butchers/create-basket")}
                          className="mt-8 w-full transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-[#F6FBEE] p-8 py-2.5 text-sm font-semibold text-olivine-500  transition-all duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100"
                        >
                          Explore Now
                        </button>
                      </div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <HomeBannerSlider />
                  </div>
                </div>
              </Tab.Panel>

              <Tab.Panel>
                <GropoolOrders />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Container>
      <Modal
        visible={showModal}
        onClose={handleOnClose}
        selected={selectedModal}
        handleRedirect={handleRedirect}
      />
    </div>
  );
}

export default SignedInHome;
