import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdDateRange } from "react-icons/md";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GropoolEmptyImage from "../../../assets/rewardBankHistoryEmptyImage.svg";
import SignedInNavbarImproved from "../../SignedInNavbarImproved";
import Container from "../my_container/Container";
import { useNavigate } from "react-router-dom";
import { authService } from "../../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { getProductShareHistory } from "../../../store/actions";
import moment from "moment";
import { CurrencyComponent } from "../../../core/helpers";

const getStartOfMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() - 3, 1);
};

const GropoolHistoryPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(getStartOfMonth);
  const [endDate, setEndDate] = useState(new Date());
  const [fetchedHistory, setFetchedHistory] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const customerId = authService.getCurrentUserCustomerId();
  const { history } = useSelector((state) => state.productShare);

  useEffect(() => {
    if (startDate && endDate) {
      handleHistoryFetch(startDate, endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (history?.length) {
      setFetchedHistory(history);
    }
  }, [history]);

  useEffect(() => {
    try {
      switch (selectedIndex) {
        case 0:
          setFetchedHistory(history);
          break;
        case 1:
          const pending = history?.filter(
            (hist) => hist?.total_remaining_slot !== 0
          );

          setFetchedHistory(pending);
          break;
        case 2:
          const completed = history?.filter(
            (hist) => hist?.total_remaining_slot === 0
          );

          setFetchedHistory(completed);
          break;
        default:
          return setFetchedHistory(history);
      }
    } catch (err) {
      return err;
    }
  }, [selectedIndex, history]);

  const handleHistoryFetch = async (from, end) => {
    try {
      const data = {
        from_date: new Date(from).toLocaleDateString("en-CA"),
        to_date: new Date(end).toLocaleDateString("en-CA"),
      };

      const payload = {
        customerId,
        data,
      };

      dispatch(getProductShareHistory(payload));
    } catch (err) {
      return err;
    }
  };

  return (
    <div>
      <SignedInNavbarImproved />
      {!history?.length ? (
        <>
          <Container className="mb-14 pt-6 @container">
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="rounded-full p-1 hover:bg-gray-200">
                <BiArrowBack
                  onClick={() => navigate(-1)}
                  className="h-6 w-6 cursor-pointer text-gray-600"
                />
              </div>

              <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
                GroPool History
              </div>
            </div>
            <div className="mt-8 flex flex-col items-center justify-center">
              <img src={GropoolEmptyImage} alt="img" className="h-44 w-auto" />
              <h2 className="mt-4 text-xl font-bold text-gravel-600">
                No GroPool Orders Yet
              </h2>
              <p className="mt-2 text-sm font-medium text-gray-500">
                "Oops, it seems like your GroPool history is empty.{" "}
              </p>
            </div>
            <div className="mx-auto mt-8 max-w-xs">
              <button className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-4 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm">
                Go to GroPool Page
              </button>
            </div>
          </Container>
        </>
      ) : (
        <>
          <Container className="mb-40 pt-6 @container">
            <div className="flex items-center space-x-2 sm:space-x-4">
              <div className="rounded-full p-1 hover:bg-gray-200">
                <BiArrowBack
                  onClick={() => navigate(-1)}
                  className="h-6 w-6 cursor-pointer text-gray-600"
                />
              </div>

              <div className="text-lg font-bold text-gravel-600 sm:text-2xl">
                GroPool History
              </div>
            </div>

            <div className="mt-10 flex flex-col @[350px]:flex-row @[350px]:items-center @[350px]:space-x-2 ">
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    className="h-10 w-full max-w-[165px] flex-1 cursor-pointer rounded-md border-gray-300 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                  <MdDateRange className="absolute right-1 top-2.5 h-5 w-5 text-olivine-500" />
                </div>
                <div className="relative">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="End Date"
                    className="h-10 w-full max-w-[165px] flex-1 cursor-pointer rounded-md border-gray-300 bg-white text-sm text-gray-600 focus:border-olivine-500 focus:ring-olivine-500"
                  />
                  <MdDateRange className="absolute right-1 top-2.5 h-5 w-5 text-olivine-500" />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <Tab.Group
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
              >
                {/*   Tab section header */}
                <Tab.List className="flex flex-wrap items-center text-sm">
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-100 px-4  py-1 text-olivine-500 outline-none"
                            : "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                        }
                      >
                        <div className="cursor-pointer">
                          <div className=" text-xs font-semibold xsm:text-sm ">
                            All GroPool orders
                          </div>
                        </div>
                      </button>
                    )}
                  </Tab>

                  <Tab as={Fragment}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-100 px-4  py-1 text-olivine-500 outline-none"
                            : "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                        }
                      >
                        <div className="cursor-pointer">
                          <div className=" text-xs font-semibold xsm:text-sm ">
                            Pending GroPool orders
                          </div>
                        </div>
                      </button>
                    )}
                  </Tab>

                  <Tab as={Fragment}>
                    {({ selected }) => (
                      /* Use the `selected` state to conditionally style the selected tab. */
                      <button
                        className={
                          selected
                            ? "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-100 px-4  py-1 text-olivine-500 outline-none"
                            : "mr-4 mt-3 border-b-[2px] border-olivine-500 border-opacity-0 px-4 py-1 text-gray-500"
                        }
                      >
                        <div className="cursor-pointer">
                          <div className=" text-xs font-semibold xsm:text-sm">
                            Completed GroPool orders
                          </div>
                        </div>
                      </button>
                    )}
                  </Tab>
                </Tab.List>

                {/*  tab Body */}
                <Tab.Panels>
                  {/* All  Plan */}
                  <Tab.Panel className="">
                    {fetchedHistory && fetchedHistory?.length
                      ? fetchedHistory?.map((hist) => (
                          <div
                            onClick={() =>
                              navigate(
                                `/gropool/history/${hist?.unique_ref_no}`
                              )
                            }
                            className="mt-5 max-w-6xl"
                          >
                            <p className="ml-4 text-[11px] font-normal text-gray-600">
                              {hist && hist?.created_date
                                ? moment(hist?.created_date).format("LL")
                                : null}
                            </p>
                            <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                              <div className="flex flex-row items-center justify-between">
                                <h3 className="text-base font-bold text-gray-700">
                                  {/* Whole Cow x10 */}
                                  {hist &&
                                  hist?.item_name &&
                                  hist?.total_purchased_slot
                                    ? `${hist?.item_name} x${hist?.total_purchased_slot}`
                                    : null}
                                </h3>
                                <p className="text-sm font-semibold text-gray-500">
                                  {hist && hist?.slot_price
                                    ? CurrencyComponent(
                                        hist?.purchased_amount_with_delivery_charge
                                      )
                                    : null}
                                </p>
                              </div>
                              {hist && hist?.total_remaining_slot === 0 ? (
                                <p className="mt-1 text-xs font-semibold text-olivine-600">
                                  Completed{" "}
                                  <span className="ml-1 font-normal text-gray-500">
                                    {hist && hist?.purchase_date
                                      ? moment(hist?.purchase_date).format("LL")
                                      : null}
                                  </span>
                                </p>
                              ) : (
                                <p className="mt-1 text-xs font-semibold text-[#D9A41C]">
                                  Awaiting slot Completion
                                </p>
                              )}

                              {hist &&
                                hist?.ref_no &&
                                hist?.gift_status_id === 1 && (
                                  <p className="mt-1 text-xs font-semibold text-olivine-600">
                                    Ref - {hist?.ref_no} /{" "}
                                    {hist?.gift_status_name}
                                  </p>
                                )}

                              {hist &&
                                hist?.ref_no &&
                                hist?.gift_status_id === 2 && (
                                  <p className="mt-1 text-xs font-semibold text-olivine-600">
                                    Ref - {hist?.ref_no} /{" "}
                                    {hist?.gift_status_name}
                                  </p>
                                )}
                            </div>
                          </div>
                        ))
                      : null}
                  </Tab.Panel>

                  {/* plans  */}
                  <Tab.Panel>
                    {fetchedHistory && fetchedHistory?.length ? (
                      fetchedHistory?.map((hist) => (
                        <div
                          onClick={() =>
                            navigate(`/gropool/history/${hist?.unique_ref_no}`)
                          }
                          className="mt-5 max-w-6xl"
                        >
                          <p className="ml-4 text-[11px] font-normal text-gray-600">
                            {hist && hist?.created_date
                              ? moment(hist?.created_date).format("LL")
                              : null}
                          </p>
                          <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                            <div className="flex flex-row items-center justify-between">
                              <h3 className="text-base font-bold text-gray-700">
                                {/* Whole Cow x10 */}
                                {hist &&
                                hist?.item_name &&
                                hist?.total_purchased_slot
                                  ? `${hist?.item_name} x${hist?.total_purchased_slot}`
                                  : null}
                              </h3>
                              <p className="text-sm font-semibold text-gray-500">
                                {hist && hist?.slot_price
                                  ? CurrencyComponent(
                                      hist?.purchased_amount_with_delivery_charge
                                    )
                                  : null}
                              </p>
                            </div>
                            <p className="mt-1 text-xs font-semibold text-[#D9A41C]">
                              Awaiting slot Completion
                            </p>
                            {hist &&
                              hist?.ref_no &&
                              hist?.gift_status_id === 1 && (
                                <p className="mt-1 text-xs font-semibold text-olivine-600">
                                  Ref - {hist?.ref_no} /{" "}
                                  {hist?.gift_status_name}
                                </p>
                              )}

                            {hist &&
                              hist?.ref_no &&
                              hist?.gift_status_id === 2 && (
                                <p className="mt-1 text-xs font-semibold text-olivine-600">
                                  Ref - {hist?.ref_no} /{" "}
                                  {hist?.gift_status_name}
                                </p>
                              )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <Container className="mt-14 pt-6 @container">
                        <div className="mt-8 flex flex-col items-center justify-center">
                          <img
                            src={GropoolEmptyImage}
                            alt="img"
                            className="h-44 w-auto"
                          />
                          <h2 className="mt-4 text-xl font-bold text-gravel-600">
                            No Pending GroPool Orders
                          </h2>
                        </div>
                      </Container>
                    )}
                  </Tab.Panel>

                  {/* reoccurring */}
                  <Tab.Panel>
                    {fetchedHistory && fetchedHistory?.length ? (
                      fetchedHistory?.map((hist) => (
                        <div
                          onClick={() =>
                            navigate(`/gropool/history/${hist?.unique_ref_no}`)
                          }
                          className="mt-5 max-w-6xl"
                        >
                          <p className="ml-4 text-[11px] font-normal text-gray-600">
                            {hist && hist?.created_date
                              ? moment(hist?.created_date).format("LL")
                              : null}
                          </p>
                          <div className="mt-1 cursor-pointer rounded-md border-[1px] border-gray-100 bg-[#F9F9F9] px-4 py-3 hover:border-gray-200">
                            <div className="flex flex-row items-center justify-between">
                              <h3 className="text-base font-bold text-gray-700">
                                {/* Whole Cow x10 */}
                                {hist &&
                                hist?.item_name &&
                                hist?.total_purchased_slot
                                  ? `${hist?.item_name} x${hist?.total_purchased_slot}`
                                  : null}
                              </h3>
                              <p className="text-sm font-semibold text-gray-500">
                                {hist && hist?.slot_price
                                  ? CurrencyComponent(
                                      hist?.purchased_amount_with_delivery_charge
                                    )
                                  : null}
                              </p>
                            </div>
                            <p className="mt-1 text-xs font-semibold text-olivine-600">
                              Completed{" "}
                              <span className="ml-1 font-normal text-gray-500">
                                {hist && hist?.purchase_date
                                  ? moment(hist?.purchase_date).format("LL")
                                  : null}
                              </span>
                            </p>
                            {hist &&
                              hist?.ref_no &&
                              hist?.gift_status_id === 1 && (
                                <p className="mt-1 text-xs font-semibold text-olivine-600">
                                  Ref - {hist?.ref_no} /{" "}
                                  {hist?.gift_status_name}
                                </p>
                              )}

                            {hist &&
                              hist?.ref_no &&
                              hist?.gift_status_id === 2 && (
                                <p className="mt-1 text-xs font-semibold text-olivine-600">
                                  Ref - {hist?.ref_no} /{" "}
                                  {hist?.gift_status_name}
                                </p>
                              )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <Container className="mt-14 pt-6 @container">
                        <div className="mt-8 flex flex-col items-center justify-center">
                          <img
                            src={GropoolEmptyImage}
                            alt="img"
                            className="h-44 w-auto"
                          />
                          <h2 className="mt-4 text-xl font-bold text-gravel-600">
                            No Completed GroPool Orders
                          </h2>
                        </div>
                      </Container>
                    )}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default GropoolHistoryPage;
