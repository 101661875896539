import { authService } from "../../services/authService";

const verification_code = authService.getCurrentUserVerificationCode();
const userId = authService.getCurrentUserID();
const customerId = authService.getCurrentUserCustomerId();

export const notification = {
  title: "Wonderful!",
  message: "Configurable",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
  animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
};

export const init_data = {
  sql_ref_no: "20",
  user_id: userId?.toString(),
  verification_code,
  option: "1",
};

export const payload = {
  sql_ref_no: "31",
  user_id: userId?.toString(),
  customer_id: customerId?.toString(),
  verification_code,
  option: "1",
};

export const userProfileData = {
  sql_ref_no: "11",
  user_id: userId?.toString(),
  customer_id: customerId?.toString(),
  option: "7",
};

export const deliveryData = {
  sql_ref_no: "11",
  user_id: userId?.toString(),
  customer_id: customerId?.toString(),
  option: "6",
};

export const ninData = {
  sql_ref_no: "11",
  user_id: userId?.toString(),
  customer_id: customerId?.toString(),
  option: "8",
};

export const generateId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const addressDescriptionValidator = (addr1, phone, areaId) => {
  const errors = {};

  if (!areaId) errors.areaId = "* area Id is required";
  if (!addr1) errors.addr1 = "* address is required";
  if (!phone) errors.phone = "* phone is required";

  return errors;
};

export const profileDescriptionPopValidator = (fname, lname, pNumber) => {
  const errors = {};

  if (!fname) errors.fname = "* first name is required";
  if (!lname) errors.lname = "* last name is required";
  if (!pNumber) errors.pNumber = "* phone number is required";

  return errors;
};

const validateEmail = (email) => {
  // Regular expression for validating email format
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const profileDescriptionValidator = (
  fname,
  lname,
  phoneNumber,
  email
) => {
  const errors = {};

  const isValid = validateEmail(email);
  if (!fname) errors.fname = "* first name is required";
  if (!lname) errors.lname = "* last name is required";
  if (!phoneNumber) errors.phoneNumber = "* phone number is required";
  if (!email) errors.email = "* email is required";
  if (!isValid) errors.email = "* email is invalid";

  return errors;
};

export const giftDescriptionValidator = (
  first_name,
  last_name,
  phone_number,
  email_address
) => {
  const errors = {};

  const isValid = validateEmail(email_address);
  if (!first_name) errors.first_name = "* first name is required";
  if (!last_name) errors.last_name = "* last name is required";
  if (!phone_number) errors.phone_number = "* phone number is required";
  if (!email_address) errors.email_address = "* email is required";
  if (!isValid) errors.email_address = "* email is invalid";

  return errors;
};

export const downloadFile = async () => {
  const fileUrl =
    "https://s3.eu-west-2.amazonaws.com/delgro-bucket.com/Voucher_Upload_Format.xlsx"; // Replace with your actual file URL

  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    // Create a temporary anchor element
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = "downloadedFile.zip"; // Specify the filename for the downloaded file

    // Append the anchor element to the body
    document.body.appendChild(downloadLink);

    // Trigger a click on the anchor element to start the download
    downloadLink.click();

    // Remove the anchor element from the body
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const updateCartPrices = (fetchedPlans, cart) => {
  if (fetchedPlans?.length && cart?.items?.length) {
    const serverCartPrices = fetchedPlans;
    const clientCartPrices = cart?.items;

    const updatedPlans = clientCartPrices?.map((cart) => {
      const equivalentCart = serverCartPrices?.find(
        (currentPrice) =>
          currentPrice?.plan_code === cart?.JSON_String?.plan?.plan_code
      );
      if (
        equivalentCart &&
        equivalentCart?.recurring_price !== cart.JSON_String?.plan?.plan_price
      ) {
        cart.JSON_String.plan.plan_price = equivalentCart?.recurring_price;
      }
      return cart;
    });

    return {
      email: cart.email,
      items: updatedPlans,
    };
  }
};

export const CurrencyComponent = (amount) => {
  const formattedAmount = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return formattedAmount;
};

export const sortByGropoolDate = (array, order = "asc") => {
  const sortArray = [...array];
  return sortArray.sort((a, b) => {
    const dateA = new Date(a.gropool_date);
    const dateB = new Date(b.gropool_date);

    if (order === "asc") {
      return dateA - dateB; // Ascending
    } else if (order === "desc") {
      return dateB - dateA; // Descending
    } else {
      throw new Error("Invalid order parameter. Use 'asc' or 'desc'.");
    }
  });
};

export const sortByRemainingSlot = (array, order = "asc") => {
  const sortArray = [...array];
  return sortArray.sort((a, b) => {
    if (order === "asc") {
      return a.total_remaining_slot - b.total_remaining_slot; // Ascending
    } else if (order === "desc") {
      return b.total_remaining_slot - a.total_remaining_slot; // Descending
    } else {
      throw new Error("Invalid order parameter. Use 'asc' or 'desc'.");
    }
  });
};
