import React, { useState } from "react";
import RecommendedSlotsComponent from "./RecommendedSlotsComponent";

const RecommendedSlots = ({ prods }) => {
  const [isSeeAll, setIsSeeAll] = useState(false);
  return (
    <div>
      <div className="mt-10 flex flex-row items-center justify-between">
        <h2 className="text-xl font-bold text-gray-700 sm:text-xl">
          Recommended Slots
        </h2>

        <button
          onClick={() => setIsSeeAll(!isSeeAll)}
          className="border-b-[1.5px] border-b-white text-base font-semibold text-[#73964C] hover:border-b-[#73964C]"
        >
          {isSeeAll ? "See Less" : "See All"}
        </button>
      </div>
      <div className="mt-6 grid gap-4 grid-auto-fit-xs">
        {prods && prods?.length
          ? isSeeAll
            ? prods.map((prd) => <RecommendedSlotsComponent prd={prd} />)
            : prods
                .slice(0, 10)
                .map((prd) => <RecommendedSlotsComponent prd={prd} />)
          : null}
      </div>
    </div>
  );
};

export default RecommendedSlots;
