import React from "react";

const ViewGiftReceipientPopup = ({ details, setOpenRecipientDetailsModal }) => {
  return (
    <div className="relative z-20 mb-28 px-2 @container sm:px-6">
      <div>
        <div className="mt-10">
          <div>
            <div className="mt-4 divide-y-[1px] divide-gray-200">
              <div className="cursor-pointer py-2">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-sm font-semibold text-gray-800">
                    Receipient Information
                  </p>
                </div>
                <p className="mt-1 text-xs text-gray-600">
                  {details?.recipient_full_name}
                </p>
                <p className="mt-1 text-xs text-gray-600">
                  {details?.recipient_phone_number}
                </p>
                <p className="mt-1 text-xs text-gray-600">
                  {details?.recipient_email_address}
                </p>
                <p className="mt-1 text-xs text-gray-600">
                  {details?.recipient_full_address_shipping}
                </p>
              </div>
            </div>
          </div>
          <div className="fixed bottom-3 right-4 flex items-center space-x-3 sm:space-x-4">
            <button
              onClick={() => setOpenRecipientDetailsModal(false)}
              className="w-full transform cursor-pointer rounded-md border-[1px] border-[#558223] bg-white px-8 py-3 text-sm font-semibold text-[#558223] transition-all duration-200 focus:outline-none @sm:max-w-[200px]"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewGiftReceipientPopup;
