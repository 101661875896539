import React from "react";
import CurrentTransactionMethodComponent from "./CurrentTransactionMethodComponent";
import EmailMethodComponent from "./EmailMethodComponent";
import PhoneNumberMethodComponent from "./PhoneNumberMethodComponent";

const ForgotTransactionPinPopup = ({ setOpenForgototp }) => {
  return (
    <div className="mb-10 px-2 sm:px-6">
      <div className="flex flex-col items-center justify-center space-x-2">
        <h2 className="mt-10 text-xl font-bold text-gray-700">
          Change Transaction PIN
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Choose your preferred method
        </p>
      </div>
      <div className="mt-4 divide-y-[0.5px] divide-gray-300 overflow-hidden rounded-xl border-[1px] border-gray-200 bg-[#F6F6F6]">
        <CurrentTransactionMethodComponent
          setOpenForgototp={setOpenForgototp}
        />
        <EmailMethodComponent setOpenForgototp={setOpenForgototp} />
        <PhoneNumberMethodComponent setOpenForgototp={setOpenForgototp} />
      </div>
    </div>
  );
};

export default ForgotTransactionPinPopup;
