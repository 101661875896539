import React, { useContext, useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import CurrentTransactionPinIcon from "../../../../assets/currentTransactionPinIcon.svg";
import LogicContext from "../../../../context/LogicContext";
import CurrentMethodChangePin from "../../profileSettings/changePin/currentPinMethod/CurrentMethodChangePin";
import NewPin from "../../profileSettings/changePin/NewPin";
import TransactionPinConfirmation from "../../profileSettings/changePin/TransactionPinConfirmation";
import { profileService } from "../../../../services/profileService";

const processStepper = {
  ENTER_OLD_PIN: "ENTER_OLD_PIN",
  ENTER_NEW_PIN: "ENTER_NEW_PIN",
  COMPLETED: "COMPLETED",
};

const CurrentTransactionMethodComponent = ({ setOpenForgototp }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(processStepper.ENTER_OLD_PIN);
  const logicContext = useContext(LogicContext);

  useEffect(() => {
    setIsLoading((prev) => !prev);
    profileService
      .getCurrentUserPin()
      .then((res) => {
        if (!res.data?.customer_info[0]?.customer_transaction_pin) {
          setActiveStep(processStepper.ENTER_NEW_PIN);
        }
        setIsLoading((prev) => !prev);
      })
      .catch((err) => {
        setIsLoading((prev) => !prev);
      });
  }, []);

  function closeModal() {
    setOpen(false);
    setOpenForgototp(false);
    setActiveStep(processStepper.ENTER_OLD_PIN);
  }

  function submitOldPin(pin) {
    setIsLoading((prev) => !prev);
    const data = { pin_number: pin };
    profileService
      .verifyPinNumber(data)
      .then((res) => {
        const filteredData = logicContext.httpResponseManager(
          res?.data?.result_status[0]
        );
        if (filteredData?.message_id === 1) {
          setActiveStep(processStepper.ENTER_NEW_PIN);
        }
        setIsLoading((prev) => !prev);
      })
      .catch((err) => {
        setIsLoading((prev) => !prev);
      });
  }

  function submitNewPin(newPin) {
    setIsLoading((prev) => !prev);
    const data = { pin_number: newPin };
    profileService
      .updateTransferPin(data)
      .then((res) => {
        const filteredData = logicContext.httpResponseManager(
          res?.data?.result_status[0]
        );
        setIsLoading((prev) => !prev);
        if (filteredData?.message_id === 1) {
          setActiveStep(processStepper.COMPLETED);
        }
      })
      .catch((err) => {
        setIsLoading((prev) => !prev);
      });
  }

  return (
    <>
      <button onClick={() => setOpen(true)} className="w-full">
        <div className="w-full cursor-pointer px-4 py-3 text-start hover:bg-[#DFE5D1] active:bg-gray-200 sm:px-6">
          <img
            src={CurrentTransactionPinIcon}
            alt="icon"
            className="h-6 w-auto"
          />
          <h3 className="mt-1 text-base font-semibold text-gray-600">
            Current Transaction Pin
          </h3>
          <p className="pb-2 text-xs font-semibold text-gray-500">
            Preferred method. Fast and safe
          </p>
        </div>
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-28 px-2 xsm:px-4">
          <Box className="mx-auto max-w-lg overflow-hidden rounded-md bg-white ">
            {activeStep === processStepper.ENTER_OLD_PIN && (
              <CurrentMethodChangePin
                isLoading={isLoading}
                closeModal={closeModal}
                submitOldPin={submitOldPin}
              />
            )}

            {activeStep === processStepper.ENTER_NEW_PIN && (
              <NewPin
                isLoading={isLoading}
                closeModal={closeModal}
                submitNewPin={submitNewPin}
              />
            )}

            {activeStep === processStepper.COMPLETED && (
              <TransactionPinConfirmation closeModal={closeModal} />
            )}
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default CurrentTransactionMethodComponent;
