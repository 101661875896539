import React from "react";
import moment from "moment";
import { CurrencyComponent } from "../../../../core/helpers";
import { useNavigate } from "react-router-dom";

const RecommendedSlotsComponent = ({ prd }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/gropool/detail/${prd?.ref_no}`)}
      className={`min-w-80 w-full cursor-pointer rounded-xl border-[1px] border-gray-200 bg-[#D5EBBB] p-4 hover:border-gray-300`}
    >
      <div className="flex h-6 w-[85px] items-center justify-center rounded-full bg-[#EDEEEC]">
        <p className="text-[11px] font-medium text-gray-500">
          {prd ? moment(prd[0]?.gropool_date).format("ll") : ""}
        </p>
      </div>
      <div className="mt-2">
        <img
          src={prd ? prd?.item_image_url : ""}
          alt="img"
          className="aspect-auto h-20 w-20 object-contain"
        />
      </div>
      <p className="mt-2 text-base font-medium text-gray-600">
        {prd?.item_name ? prd?.item_name : ""}
      </p>
      <div className="mt-1 flex flex-row items-center space-x-1">
        <p className="text-sm font-bold text-gray-700">
          {prd?.slot_price ? CurrencyComponent(prd?.slot_price) : null}
          <span className="text-xs font-medium">/slot</span>
        </p>
        <p className="w-fit rounded-full bg-[#9BAA88] px-2 py-1 text-xs font-normal text-white">
          {prd?.total_remaining_slot
            ? prd?.total_remaining_slot > 1
              ? `${prd?.total_remaining_slot} slots Left`
              : `${prd?.total_remaining_slot} slot Left`
            : null}
        </p>
      </div>
    </div>
  );
};

export default RecommendedSlotsComponent;
