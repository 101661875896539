import React, { useContext, useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import EmailAddressIcon from "../../../../assets/emailAddressIcon.svg";
import LogicContext from "../../../../context/LogicContext";
import { authService } from "../../../../services/authService";
import EmailMethodChangePin from "../../profileSettings/changePin/emailMethod/EmailMethodChangePin";
import EmailOTP from "../../profileSettings/changePin/emailMethod/EmailOTP";
import NewPin from "../../profileSettings/changePin/NewPin";
import { toast } from "react-hot-toast";
import TransactionPinConfirmation from "../../profileSettings/changePin/TransactionPinConfirmation";
import { profileService } from "../../../../services/profileService";

const processStepper = {
  SUBMIT_EMAIL: "SUBMIT_EMAIL",
  ENTER_OTP: "ENTER_OTP",
  NEW_PIN: "NEW_PIN",
  COMPLETED: "COMPLETED",
};

const EmailMethodComponent = ({ setOpenForgototp }) => {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(processStepper.SUBMIT_EMAIL);
  const [isLoading, setIsLoading] = useState(false);
  const logicContext = useContext(LogicContext);

  useEffect(() => {
    setActiveStep(processStepper.SUBMIT_EMAIL);
  }, []);

  function closeModal() {
    setOpen(false);
    setOpenForgototp(false);
    setActiveStep(processStepper.SUBMIT_EMAIL);
  }

  function submitEmail(email) {
    const payload = {
      sql_ref_no: "6",
      user_email_address: email,
      option: "2",
    };
    setIsLoading((prev) => !prev);
    authService
      .forgotPassword(payload)
      .then((apiResponse) => {
        setIsLoading((prev) => !prev);
        const filteredData = logicContext.httpResponseManager(
          apiResponse?.data?.result_status[0]
        );

        if (filteredData?.message_id == 1) {
          toast.success(`Verification Code has been sent to your Email.`);
          setActiveStep(processStepper.ENTER_OTP);
        }
      })
      .catch((err) => {
        setIsLoading((prev) => !prev);
      });
  }

  function verifyOtp(otp) {
    setIsLoading((prev) => !prev);
    let payload = {
      sql_ref_no: "7",
      user_id: authService.getCurrentUserID(),
      verification_code: otp,
      option: "1",
    };

    authService.emailVerification(payload).then((res) => {
      setIsLoading((prev) => !prev);
      const filteredData = logicContext.httpResponseManager(res?.data);
      if (filteredData.message_id == 1) {
        setActiveStep(processStepper.NEW_PIN);
      }
    });
  }

  function submitNewPin(newPin) {
    setIsLoading((prev) => !prev);
    const data = { pin_number: newPin };
    profileService
      .updateTransferPin(data)
      .then((res) => {
        const filteredData = logicContext.httpResponseManager(
          res?.data?.result_status[0]
        );
        setIsLoading((prev) => !prev);
        if (filteredData?.message_id === 1) {
          setActiveStep(processStepper.COMPLETED);
        }
      })
      .catch((err) => {
        setIsLoading((prev) => !prev);
      });
  }
  return (
    <>
      <button onClick={() => setOpen(true)} className="w-full">
        <div className="w-full cursor-pointer px-4 py-3 text-start hover:bg-[#DFE5D1] active:bg-gray-200 sm:px-6">
          <img src={EmailAddressIcon} alt="icon" className="h-6 w-auto" />
          <h3 className="mt-1 text-base font-semibold text-gray-600">
            Email Address
          </h3>
          <p className="pb-2 text-xs font-semibold text-gray-500">
            Input your registered phone number and an otp will be sent.
          </p>
        </div>
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mt-28 px-2 xsm:px-4">
          <Box className="mx-auto max-w-lg overflow-hidden rounded-md bg-white">
            {activeStep === processStepper.SUBMIT_EMAIL && (
              <EmailMethodChangePin
                closeModal={closeModal}
                submitEmail={submitEmail}
                isLoading={isLoading}
              />
            )}

            {activeStep === processStepper.ENTER_OTP && (
              <EmailOTP
                closeModal={closeModal}
                verifyOtp={verifyOtp}
                isLoading={isLoading}
                reSendOtp={submitEmail}
                channel={authService.getCurrentUserEmail()}
              />
            )}

            {activeStep === processStepper.NEW_PIN && (
              <NewPin
                closeModal={closeModal}
                submitNewPin={submitNewPin}
                isLoading={isLoading}
              />
            )}

            {activeStep === processStepper.COMPLETED && (
              <TransactionPinConfirmation closeModal={closeModal} />
            )}
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default EmailMethodComponent;
