import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RadioGroup } from "@headlessui/react";
import { CurrencyComponent } from "../../../../core/helpers";

const SelectSlotPurchaseOptions = ({
  fetchedCreateInfo,
  setOpenSelectSlotModal,
  setOpenPurchaseSummaryModal,
  setFetchedCreateInfo,
  setOpenGropoolModal,
  setOpenRecipientDetailsModal,
  setPurchasingFor,
  purchasingFor,
  setIsGift,
}) => {
  const [count, setCount] = useState(
    (fetchedCreateInfo && +fetchedCreateInfo?.data?.purchase_slot) || 1
  );

  const [totalSlotPurchasePrice, setTotalSlotPurchasePrice] = useState(0);

  useEffect(() => {
    if (fetchedCreateInfo && fetchedCreateInfo?.data?.item_price) {
      const newTotal = +fetchedCreateInfo?.data?.item_price * count;
      setTotalSlotPurchasePrice(newTotal);
    }
  }, [fetchedCreateInfo, count]);

  const handleProceed = () => {
    const updatedData = {
      ...fetchedCreateInfo?.data,
      purchase_slot: count.toString(),
      item_price: totalSlotPurchasePrice.toString(),
      gift_status_id: purchasingFor === "myself" ? "2" : "1",
    };

    const payload = {
      ...fetchedCreateInfo,
      data: updatedData,
      purchasingFor,
    };

    setFetchedCreateInfo(payload);
    setOpenSelectSlotModal(false);
    if (purchasingFor === "gift") {
      setIsGift(true);
      setOpenRecipientDetailsModal(true);
    } else {
      setIsGift(false);
      setOpenPurchaseSummaryModal(true);
    }
  };

  const handleDecrement = () => {
    if (count === 1) {
      return;
    }
    setCount((prev) => prev - 1);
  };

  const handleIncrement = () => {
    if (count >= +fetchedCreateInfo?.data?.slot_number) {
      return;
    }
    setCount((prev) => prev + 1);
  };

  const goBack = () => {
    setOpenGropoolModal(true);
    setOpenSelectSlotModal(false);
  };

  return (
    <div className="mb-6 px-2 sm:px-6">
      <h2 className="mt-6 text-xl font-semibold text-gray-700">
        Select Slot Purchase Options
      </h2>
      <div className="mt-6 rounded-lg border-[1px] border-[#DFE5D1] bg-[#F9F9F9] px-4 py-8 shadow-xl shadow-[#F6F6F5]">
        <div className="flex flex-row items-center space-x-4">
          <div className="flex flex-row items-center">
            <button
              onClick={() => handleDecrement()}
              className="rounded-md border-[1px] border-gray-200 bg-[#E8EFE1] p-2 hover:bg-gray-200 active:bg-[#E8EFE1]"
            >
              <FaMinus className="h-3 w-3 text-gray-600" />
            </button>
            <div className="px-2 text-sm font-medium text-gray-700">
              {count} Slot
            </div>
            <button
              onClick={() => handleIncrement()}
              className="rounded-md border-[1px] border-gray-200 bg-[#E8EFE1] p-2 hover:bg-gray-200 active:bg-[#E8EFE1]"
            >
              <FaPlus className="h-3 w-3 text-gray-600" />
            </button>
          </div>
          <div className="rounded-md bg-[#EFEDED] px-4 py-1 text-base font-bold text-gray-800">
            {CurrencyComponent(totalSlotPurchasePrice)}
          </div>
        </div>

        <div className="mt-5 @container">
          <RadioGroup value={purchasingFor} onChange={setPurchasingFor}>
            <RadioGroup.Label className="text-sm font-semibold text-gray-700">
              Who are you purchasing this slot for?
            </RadioGroup.Label>
            <div className=" mt-2 flex flex-col @sm:flex-row @sm:items-center">
              <RadioGroup.Option value="myself">
                {({ checked }) => (
                  <p
                    className={`mr-1 w-fit cursor-pointer rounded-full px-3 py-1.5 text-xs  ${
                      checked
                        ? "bg-[#558223] text-white"
                        : "bg-[#E5E3E3] text-gray-600"
                    }`}
                  >
                    I am purchasing for myself
                  </p>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option
                value="gift"
                className="mt-2 rounded-full px-2 text-xs @sm:mt-0"
              >
                {({ checked }) => (
                  <p
                    className={`w-fit cursor-pointer rounded-full px-3 py-1.5 text-xs  ${
                      checked
                        ? "bg-[#558223] text-white"
                        : "bg-[#E5E3E3] text-gray-600"
                    }`}
                  >
                    I am purchasing as a Gift
                  </p>
                )}
              </RadioGroup.Option>
            </div>
          </RadioGroup>
        </div>
      </div>
      <div className="mt-8 flex items-center justify-between">
        <button
          onClick={() => goBack()}
          className="w-fit transform cursor-pointer rounded-md border-[1px] border-gray-300 bg-white px-10 py-3 text-sm font-semibold text-gray-600 transition-all duration-200 focus:outline-none"
        >
          Cancel
        </button>
        <button
          onClick={() => handleProceed()}
          className={`w-fit transform rounded-md px-10 py-3 text-sm font-semibold text-white transition-all duration-200 focus:outline-none @sm:max-w-[180px] ${
            purchasingFor
              ? "cursor-pointer bg-[#558223] hover:bg-olivine-500/80 active:bg-olivine-600"
              : "cursor-disabled cursor-not-allowed bg-gray-400"
          }`}
          disabled={!purchasingFor}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default SelectSlotPurchaseOptions;
