import { useState } from "react";
import CreateAccount from "./CreateAccount";
import RegisterForm from "./RegisterForm";
import { useLocation } from "react-router-dom";
import { authService } from "../../../services/authService";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const RegisterPage = () => {
  const { isFromLogin } = useSelector((state) => state.modal);
  const [currentPage, setCurrentPage] = useState(isFromLogin ? 2 : 1);
  const [corporate_option, setCorporate_option] = useState(
    isFromLogin ? "CORPORATION" : "INDIVIDUAL"
  );
  const [corValue, setCorValue] = useState("");
  const [secKey, setSecKey] = useState("");
  const [paramList, setParamList] = useState([""]);
  const { search } = useLocation();

  console.log("corporate_option", corporate_option);

  useEffect(() => {
    const paramDataList = search.split("&");
    setParamList(paramDataList);
    try {
      if (paramDataList?.length === 2) {
        let corporate = paramDataList[0].split("=")[1];
        let securityKey = paramDataList[1].split("=")[1];

        authService
          .checkCorporateKey({ corporate: corporate, key: securityKey })
          .then((res) => {
            if (res.data?.Corporate_Info?.length) {
              setCorValue(corporate);
              setSecKey(securityKey);
              setCorporate_option("CORPORATION");
              setCurrentPage(2);
            } else {
              setParamList([""]);
            }
          });
      }
    } catch (error) {
      setParamList([""]);
      setSecKey("");
    }
  }, [search]);

  const setPageNUmber = (num) => {
    if (corporate_option === "CORPORATION_ADMIN") {
      return window.location.replace(process.env.REACT_APP_CORPORATE_LINK);
    }
    setCurrentPage(num);
  };

  const setCorporateCode = (option) => {
    setCorporate_option(option);
  };

  return (
    <>
      {currentPage === 1 && (
        <CreateAccount
          setCorporateCode={setCorporateCode}
          setPageNUmber={setPageNUmber}
          paramDataList={paramList}
        />
      )}
      {currentPage === 2 && (
        <RegisterForm
          setPageNUmber={setPageNUmber}
          corporate_option={corporate_option}
          corValue={corValue}
          secKey={secKey}
        />
      )}
    </>
  );
};

export default RegisterPage;
